.columns {
  float: left;
  min-width: 330px;
  width: 100%;
  max-width: 430px;
  padding: 8px;
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 20px;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(48, 22, 22, 0.2);
}

.grey.bottom {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.price .header {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #e76659;
  color: white;
  font-family: var(--font-bold);
  font-size: 25px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}

.button {
  background-color: #e76659;
  border: none;
  color: white;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-family: var(--font-bold);
}

@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}
