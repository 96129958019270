body {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(77, 77, 77, 0.8);
    background-color: #f5f5f5;
  }

  div::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  div::-webkit-scrollbar-thumb {
    background-color: lightblue;
    border: 1px solid #cccccc;
  }
}

* {
  -webkit-overflow-scrolling: touch;
}

.colored {
  color: rgb(231, 102, 89);
}

:root {
  --theme-color-bg: #ffdebf;
  --theme-color: rgb(231, 102, 89);
  --theme-color-dark: #ed7700;
  --theme-color-light: #ffdebf;
  --theme-disabled: #d4d4d4;
  --line-color: #b2b2b2;
  --icon-color: #898989;
  --secondary-color: #898989;
  --table-color: #e9e9e9;
  --secondary-disabled: #bebebe;
  --secondary-hover: #5e5e5e;
  --header-color: #fafafa;
  --second-avatar: #b23b3b;
  --first-avatar: #618bbb;
  --line-grey: #dddddd;
  --green: #4caf50;
  --once-icon: #00bcd4;
  --max-width: 1180px;

  --green-light: #4caf5052;
  --font-header: "Ubuntu";
  --font-normal: apercu-regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --font-medium: apercu-medium, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --font-bold: apercu-bold, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@keyframes running1 {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
  100% {
    top: 0px;
  }
}

div.mfhidden {
  opacity: 0;
  z-index: -1000;
  transition: all 0.15s;
}

.tcb-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  stroke-width: 0;
  stroke: #e76659;
  fill: #e76659;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.helpplus {
  width: 1em;
  height: 1em;
  line-height: 1em;
}
